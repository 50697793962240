// Generated by Framer (e1a43d5)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-TgW6d"

const variantClassNames = {XDAolQ5s0: "framer-v-c4l4vf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "XDAolQ5s0", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-c4l4vf", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XDAolQ5s0"} ref={refBinding} style={{background: "linear-gradient(90deg, rgb(58, 42, 181) -1.3513513513513513%, rgb(85, 64, 247) 27.027027027027028%, rgb(85, 64, 247) 71.47150812922297%, rgb(58, 42, 181) 100%)", ...style}}><motion.div className={"framer-7grgbw"} layoutDependency={layoutDependency} layoutId={"CfNEzPn1K"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTGlnaHQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "300", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>🚀 Participate in Dropie's Exclusive Beta!</motion.p></React.Fragment>} className={"framer-w5fx80"} fonts={["Inter-Light"]} layoutDependency={layoutDependency} layoutId={"kGN_elCsc"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TgW6d.framer-14i4cal, .framer-TgW6d .framer-14i4cal { display: block; }", ".framer-TgW6d.framer-c4l4vf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 35px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 1200px; }", ".framer-TgW6d .framer-7grgbw { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 25px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 1px; }", ".framer-TgW6d .framer-w5fx80 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-TgW6d.framer-c4l4vf, .framer-TgW6d .framer-7grgbw { gap: 0px; } .framer-TgW6d.framer-c4l4vf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-TgW6d.framer-c4l4vf > :first-child, .framer-TgW6d .framer-7grgbw > :first-child { margin-left: 0px; } .framer-TgW6d.framer-c4l4vf > :last-child, .framer-TgW6d .framer-7grgbw > :last-child { margin-right: 0px; } .framer-TgW6d .framer-7grgbw > * { margin: 0px; margin-left: calc(25px / 2); margin-right: calc(25px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNFlreJOii: React.ComponentType<Props> = withCSS(Component, css, "framer-TgW6d") as typeof Component;
export default FramerNFlreJOii;

FramerNFlreJOii.displayName = "BAR";

FramerNFlreJOii.defaultProps = {height: 35, width: 1200};

addFonts(FramerNFlreJOii, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/BkDpl4ghaqvMi1btKFyG2tdbec.woff2", weight: "300"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/zAMK70AQRFSShJgUiaR5IiIhgzk.woff2", weight: "300"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/IETjvc5qzUaRoaruDpPSwCUM8.woff2", weight: "300"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/oLCoaT3ioA0fHdJnWR9W6k7NY.woff2", weight: "300"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/Sj0PCHQSBjFmEp6NBWg6FNaKc.woff2", weight: "300"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/v2q8JTTTs7McDMSEhnxAIBqd0.woff2", weight: "300"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/H4TfENUY1rh8R9UaSD6vngjJP3M.woff2", weight: "300"}]}], {supportsExplicitInterCodegen: true})